<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" size="small" @click="showAddDialogForm"
                >新增会议</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="search"
                >刷新</el-button
              >
            </el-form-item>
          </el-form>

          <!--          <el-form :inline="true" size="medium" :model="searchForm">-->
          <!--            <el-form-item label="用户名">-->
          <!--              <el-input v-model="searchForm.username" size="small" placeholder="用户名"></el-input>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="昵称">-->
          <!--              <el-input v-model="searchForm.nickname" size="small" placeholder="昵称"></el-input>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item>-->
          <!--              <el-button type="primary" size="small"   @click="search">查询</el-button>-->
          <!--            </el-form-item>-->
          <!--          </el-form>-->
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column prop="id" label="id" min-width="100"> </el-table-column>
      <el-table-column prop="title" label="会议名称" min-width="220">
      </el-table-column>
      <el-table-column prop="subject" label="所属单元" min-width="120">
      </el-table-column>
      <el-table-column prop="bmEndTime" label="报名截止时间" min-width="100">
        <template slot-scope="scope">
          {{ fmtDate(scope.row.bmEndTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="开始时间" min-width="100">
        <template slot-scope="scope">
          {{ fmtDate(scope.row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间" min-width="100">
        <template slot-scope="scope">
          {{ fmtDate(scope.row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="地址" min-width="200">
      </el-table-column>
      <el-table-column prop="lat" label="维度" min-width="100">
      </el-table-column>
      <el-table-column prop="lon" label="经度" min-width="100">
      </el-table-column>
      <el-table-column prop="sortBy" label="排序" min-width="80">
      </el-table-column>
      <el-table-column prop="timeStr" label="显示时间" min-width="180">
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        fixed="right"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :total="total"
          :page-sizes="[20, 50, 100]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      :title="editForm.id == 0 ? '新增会议' : '修改会议'"
      :visible.sync="editFormVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="会议名称" label-width="100px" prop="title">
          <el-input
            v-model="editForm.title"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属单元" label-width="120px" prop="subject">
          <el-radio
            v-for="item in subjectList"
            v-model="editForm.subject"
            :label="item.title"
            :key="item.title"
            >{{ item.title }}</el-radio
          >
        </el-form-item>
        <!-- 定向类型
0 非定向
1 定向 -->
        <el-form-item label="定向类型" prop="plate_type">
          <el-radio v-model="editForm.plate_type" :label="0">非定向</el-radio>
          <el-radio v-model="editForm.plate_type" :label="1">定向</el-radio>
        </el-form-item>
        <el-form-item label="是否允许观众注册" prop="authWatch">
          <el-radio v-model="editForm.authWatch" :label="0">不允许</el-radio>
          <el-radio v-model="editForm.authWatch" :label="1">允许</el-radio>
        </el-form-item>
        <el-form-item label="报名截止时间" label-width="120px" prop="bmEndTime">
          <el-date-picker
            @change="onDateChange(1)"
            v-model="date.date1"
            size="mini"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            type="datetime"
            placeholder="请选择日期和时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="会议开始时间" label-width="120px" prop="startTime">
          <el-date-picker
            @change="onDateChange(2)"
            v-model="date.date2"
            size="mini"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            type="datetime"
            placeholder="请选择日期和时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="会议结束时间" label-width="120px" prop="endTime">
          <el-date-picker
            @change="onDateChange(3)"
            v-model="date.date3"
            size="mini"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            type="datetime"
            placeholder="请选择日期和时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="会议地址" label-width="120px" prop="address">
          <el-input
            v-model="editForm.address"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="维度" label-width="120px" prop="lat">
          <el-input
            v-model="editForm.lat"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="经度" label-width="120px" prop="lon">
          <el-input
            v-model="editForm.lon"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" label-width="120px" prop="sortBy">
          <el-input
            v-model="editForm.sortBy"
            type="number"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片" label-width="120px" prop="topimg">
          <el-input
            v-model="editForm.topimg"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示时间" label-width="120px" prop="timeStr">
          <el-input
            v-model="editForm.timeStr"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="会议内容" label-width="120px" prop="content">
          <VueUeditorWrap v-model="editForm.content" :config="editorConfig" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getPlateList2, addPlate, updatePlate } from "../../../api/plate";
import { getSystemSet, deleteUser } from "../../../api/system_setting";
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  name: "plateManage",
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      editorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: "100%",
        zIndex: 5000,
        // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
        // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
        // serverUrl: '/static/UEditor/php/controller.php',
        UEDITOR_HOME_URL: "/UEditor/",
      },

      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        title: "",
        subject: "",
        bmEndTime: "",
        startTime: "",
        endTime: "",
        address: "",
        content: "",
        lat: "",
        lon: "",
        sortBy: "",
        topimg: "",
        timeStr: "",
        plate_type: 0,
        authWatch: 1,
      },
      date: {
        date1: "",
        date2: "",
        date3: "",
      },
      editRules: {
        plate_type: [
          { required: true, message: "请选择定向类型", trigger: "change" },
        ],

        title: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        subject: [
          { required: true, message: "请选择主题单元", trigger: "blur" },
        ],
        bmEndTime: [
          { required: true, message: "请选择报名结束时间", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择会议开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择会议结束时间", trigger: "blur" },
        ],
      },
      loadingDiv: null,
      searchForm: {
        username: "",
        nickname: "",
      },
      subjectList: [],
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };

    this.init();
  },
  methods: {
    onDateChange(val) {
      if (this.date.date1) {
        this.editForm.bmEndTime = this.date.date1 / 1000;
      }
      if (this.date.date2) {
        this.editForm.startTime = this.date.date2 / 1000;
      }
      if (this.date.date3) {
        this.editForm.endTime = this.date.date3 / 1000;
      }
    },
    fmtDate(val) {
      return util.fmtDate(new Date(val * 1000));
    },
    init() {
      getSystemSet({ group: "plate_unit" }).then((res) => {
        if (res.code) {
          this.subjectList = res.data;
          // this.workGroupList.unshift({title:"请选择部门/组", value: ''});
          // for (let i in res.data){
          //   this.workGroupList.push({title: res.data[i].title, value: res.data[i].title})
          // }
        }
      });
    },

    showAddDialogForm() {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.title = "";
      this.editForm.subject = "";
      this.editForm.content = "";

      this.editForm.bmEndTime = "";
      this.editForm.startTime = "";
      this.editForm.endTime = "";
      this.date.date1 = "";
      this.date.date2 = "";
      this.date.date3 = "";

      this.editForm.lat = "";
      this.editForm.lon = "";
      this.editForm.topimg = "";
      this.editForm.sortBy = 999;
      this.editForm.address = "";
      this.editForm.timeStr = "";

      this.editFormVisible = true;
    },
    handleEdit(index, row) {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = row.id;
      this.editForm.title = row.title;
      this.editForm.subject = row.subject;
      this.editForm.content = row.content;

      this.editForm.bmEndTime = row.bmEndTime * 1000;
      this.editForm.startTime = row.startTime * 1000;
      this.editForm.endTime = row.endTime * 1000;
      this.date.date1 = row.bmEndTime * 1000;
      this.date.date2 = row.startTime * 1000;
      this.date.date3 = row.endTime * 1000;

      this.editForm.lat = row.lat;
      this.editForm.lon = row.lon;
      this.editForm.sortBy = row.sortBy;
      this.editForm.topimg = row.topimg;
      this.editForm.timeStr = row.timeStr;

      this.editForm.address = row.address;
      console.log(row);

      this.editFormVisible = true;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }

          if (this.date.date1) {
            this.editForm.bmEndTime = this.date.date1 / 1000;
          }
          if (this.date.date2) {
            this.editForm.startTime = this.date.date2 / 1000;
          }
          if (this.date.date3) {
            this.editForm.endTime = this.date.date3 / 1000;
          }

          const loadingDiv = util.showLoading(loadingText);
          let func = updatePlate;
          if (this.editForm.id == 0) {
            func = addPlate;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm(
        "是否确认删除会议:" + row.title + "(会议名:" + row.title + ")",
        "删除确认"
      )
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteUser({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getPlateList2({
        pageSize: this.pageSize,
        pageNum: pageNum,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.el-form-item__content {
  line-height: 0px;
}
.el-loading-custom-class {
  z-index: 99999 !important;
}
</style>
